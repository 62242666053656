import {
  Component,
  ElementRef,
  OnInit,
} from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@e/environment';
import { con, PAGE, VAR } from './shared';
import { ROUTE } from './shared/routing';
import { PersistenceService } from './services/persistence.service';
import { console } from '@shared';
import { initDefaultTrace } from './app.effects';

import {
  State,
  loadingSelector,
  languageSelector,
  authenticatedSelector,
  usernameSelector,
  globalVariableSelector,
  STORE_PERSISTED,
  traceSelector,
  windowRectSelector,
} from './reducers';
import {
  setLoadingStatus,
  actionLogin,
  setVariable,
  setTrace,
  initTrace,
  actionLogout,
  setNamedTrace,
  setLogLevel,
} from './reducers/global/actions';
import { Title } from '@angular/platform-browser';
import { LogLevel, TraceSettings } from './reducers/types';

const IPPORTALID = 'wipo-portal';
@Component({
  selector: 'app-root', templateUrl: './app.component.html', host: {
    'globallinkclick': ''
  }
})
export class AppComponent implements OnInit {
  env = environment;
  title = 'IPPortal';
  helpOptions: { code: string; link?: string }[];
  helpMore: { name: string; link?: string, code?: string, targetTab?: string }[];
  loading: Observable<boolean>;
  isAuthenticated: boolean;
  username: string;
  sso: string;
  navbarDisableBell = 'false';
  lang: string;
  showMegamenu: boolean;
  disableBell: boolean;
  languageMenu = environment.availableLanguages.map(l => ({ code: l }));
  activePage: string;

  constructor(
    public persistence: PersistenceService,
    private titleSrv: Title,
    protected store: Store<State>,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    con.log('alerts', 'app init');
    this.loading = this.store.pipe(select(loadingSelector));
    this.store.dispatch(setLoadingStatus(false));
    // set trace options from url
    this.route.queryParamMap.subscribe(m => {
      const trace = m.get('trace')?.split(',');
      if (trace) {
        const tracable = 'routing,store,authentication,events'.split(',');
        // not explicitly tracable will go into named list 
        const named = trace.filter(t => t && !tracable.includes(t));
        const traceSettings = tracable.reduce((tt, t) => {
          tt[t] = trace.includes(t);
          return tt;
        }, { named } as TraceSettings);
        initDefaultTrace(this.store, traceSettings);
        console.record(LogLevel.WARN, 'ipportal', 'APP, tracing', traceSettings, '(other) reserved names', tracable);
        this.store.pipe(select(traceSelector)).subscribe(t =>
          console.record(LogLevel.WARN, 'ipportal', 'TRACE', t)
        );
      }
      const level = m.get('log-level');
      if (level !== undefined) {
        this.store.dispatch(setLogLevel(+level));
      }
    });
    // force it false, so we do not use persisted state with menu open
    this.store.dispatch(setVariable(VAR.showMegamenu, false));
    this.store.dispatch(setVariable(VAR.disableBell, false));
    combineLatest([
      this.store.pipe(select(authenticatedSelector)),
      this.store.pipe(select(usernameSelector))])
      .subscribe(([authState, username]) => {
        this.isAuthenticated = authState;
        if (this.isAuthenticated) {
          this.username = username;
          this.sso = 'auth-basic';
        } else {
          this.username = undefined;
          this.sso = 'unauthenticated';//undefined;
        }
      });
    this.store
      .select(globalVariableSelector(VAR.activePage))
      .subscribe((a: string) => {
        setTimeout(() => {
          this.activePage = a ? a : PAGE.LANDING;
        });
      });
    // we use sso parameter only for mock authentication, else rely on Navbar
    if (environment.mockAuth) {
      this.sso = environment.mock2FA ? 'auth-strong' : 'auth-basic';
    }

    this.store.pipe(select(languageSelector)).subscribe(l => {
      (this.lang = l);
      this.helpOptions = [
        {
          code: 'contact',
          link: environment.contactPage + this.lang + environment.contactArea
        },
        {
          code: 'faq',
        },
        {
          code: 'termsOfUse',
          link:
            environment.wipoSiteTools +
            this.lang +
            environment.wipoSiteDisclaimPage
        },
        {
          code: 'privacyPolicy',
          link:
            environment.wipoSiteTools +
            this.lang +
            environment.wipoSitePrivacyPage
        }
      ];
    });
    this.translate
      .stream(['navbar.help.onboard'])
      .subscribe(t => {
        this.helpMore = [
          {
            name: t['navbar.help.onboard'],
            code: 'about',
            targetTab: 'ipportal_about',
          }
        ];
      });

  window.addEventListener('wipoHelpClick', e => {
        const { detail } = e as any;
      if (detail?.helpSelected) {
        const replaceLanguage = (url: string) => url.replace('/en', `/${this.lang}/`);
      
        switch (detail.helpSelected) {
          case 'faq':
          case 'about':
            document.location.href = replaceLanguage(environment.ipPortalSupport) + detail.helpSelected;
            break;
          default:
            if (!detail.linkUrl) {
              this.router.navigate([detail.helpSelected]);
            }
            break;
        }
      }
    });
    window.addEventListener('wipoNotificationClick', e => {
      this.router.navigate([ROUTE.ALERTS]);
    });
    // NRMS: in DEV can log in by default
    if (environment.autoLogin) {
      this.store.dispatch(actionLogin());
    }
    // smartling capture lib
    if (environment.enableSmartlingCapture) {
      this.initSmartLing();
    }
    // switch direction depending on language
    this.store.pipe(select(languageSelector)).subscribe(lang => {
      const dir = lang === 'ar' ? 'rtl' : 'ltr';
      document.body.style.direction = dir;
      document.documentElement.setAttribute('dir', dir);
      document.documentElement.setAttribute('lang', lang);
      // enable/disable smartling tracker
      toggleSmartling((window as any).SmartlingContextTracker, this);
    });
    this.store
      .select(globalVariableSelector(VAR.showMegamenu))
      .subscribe((t: boolean) => {
        setTimeout(() => {
          this.showMegamenu = t;
        });
      });

    this.store
      .pipe(select(globalVariableSelector(VAR.disableBell)))
      .subscribe((t: boolean) => {
        setTimeout(() => {
          this.disableBell = t;
        });
      });
    // trace
    this.store.select(globalVariableSelector(VAR.activePage)).subscribe(p => {
      this.titleSrv.setTitle(`WIPO IP Portal (${p})`);
    });
  }

  public approveDisclaimer() {
    // disclaimer is shown while in anonymous state, hence we cannot use persistence API
    this.persistence.setLocalVariable(IPPORTALID, 'disclaimer', false);
  }
  private initSmartLing() {
    (function (w, o, app) {
      try {
        const h = document.getElementsByTagName('head')[0];
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.crossOrigin = 'anonymous';
        s.src = '//d2c7xlmseob604.cloudfront.net/tracker.min.js';
        s.onload = function () {
          const smartlingTracker = w['SmartlingContextTracker'];
          smartlingTracker.init({ orgId: o });
          toggleSmartling(smartlingTracker, app); // disable if lang not English
        };
        h.insertBefore(s, h.firstChild);
      } catch (ex) { }
    })(window, 'Z50+K9sf7aeeLpi0yPL+qw', this);
  }
  /* debug */
  cleanAndReload() {
    this.store.dispatch(actionLogout());
    sessionStorage.setItem(STORE_PERSISTED, null);
    window.location.reload();
  }
}
const toggleSmartling = (smartlingTracker, app) => {
  if (smartlingTracker) {
    if (app.lang === 'en') {
      console.log('SMARTLING CAPTURE ENABLE');
      smartlingTracker.enable();
    } else {
      console.log('SMARTLING CAPTURE DISABLE');
      smartlingTracker.disable();
    }
  }
};
