export const common_environment = {
  name: 'common',
  webcomponentsPath: '/webcomponents',
  webcomponentsCdnJs: 'https://web.cdnjs.webcms.dev.web1.wipo.int/universal-lf-webcomponents', 
  footerVersion: 'latest',
  
  loadNavbar: true, // set false for development wo navbar
  navbarUlrs: [
    '/polyfills/webcomponents-loader.js',
    '/wipo-init/wipo-init.js',
    '/wipo-navbar/wipo-navbar.js',
  ],
  trace: { // initial state
    store: true,
    routing: true,
    authentication: true,
    events: false, 
    named:[] // any custom names
  },
  logLevel: 0, // WARN
// 0 - debug, 1 - log, 2 -warn, 3 - error
  ANONYMOUS_URLS: [
    'wipo-navbar/assets',
    'wipopearl.wipo.int',
    'api.wipopearl',
    'psNbRecs.jsf',
    'api.ipp-persistence',
    'wipolex.globaldb',
    'wipolex.sdd',
    'wipolex.wipo',
    'wipolex-api',
    'api.geolocation',
    'api.ipstatsdc'

  ],
  urlGoogle: 'https://www.google.com/',
  languageResources: [
    { namespace: undefined, prefix: './assets/i18n/', suffix: '.json' },
    // define additional translation file patterns here!
    { namespace: 'numeral', prefix: './assets/i18n/promo/', suffix: '.json' },
    { namespace: 'numeral', prefix: './assets/i18n/numerals/', suffix: '.json' },
    { namespace: 'about', prefix: './assets/i18n/about/', suffix: '.json' },
    { namespace: 'news', prefix: './assets/i18n/news/', suffix: '.json' },
    { namespace: 'promo', prefix: './assets/i18n/promo/', suffix: '.json' },
    { namespace: 'countries', prefix: './assets/i18n/countries/', suffix: '.json' },
    { namespace: undefined, prefix: './assets/i18n/special/', suffix: '.json' },
    { namespace: undefined, prefix: './assets/i18n/wipopearl/', suffix: '.json' }
  ],
  availableLanguages: ['ar', 'en', 'es', 'fr', 'ru', 'zh'],
  additionalLanguages: ['de', 'ja', 'ko', 'pt'], // new set of languages
  defaultLanguage: 'en',
  localeMap: {
    ar: 'ar-ma',
    zh: 'zh-cn'
  },
  languageNames: {
    'ar': 'عربي',
    'de': 'Deutsch',
    'en': 'English',
    'es': 'Español',
    'et': 'Eesti',
    'fr': 'Français',
    'ja': '日本語',
    'ko': '한국어',
    'pt': 'Português',
    'pt-BR': 'Português (Brasil)',
    'ru': 'Русский',
    'zh': '中文',
    'ka': 'ქართული',
    'it': 'Italiano',
    'nl': 'Nederlands',
    'lt': 'Lietuvių Kalba',
    'he': 'עִבְרִית',
    'mn': 'Монгол хэл',
    'no': 'Norsk',
    'sr': 'српски језик',
    'tr': 'Türkçe',
    'vi': 'Tiếng Việt',
    'uk': 'Українська',
    'km': 'ខ្មែរ',
    'fa': 'فارسی',
    'lo': 'ພາສາລາວ',
    'id': 'Bahasa Indonesia',
    'hy': 'Հայերեն',
    'bg': 'български език',
    'ro': 'Română'
  },

  /* mocking */
  mockupData: false, // to be phased out
  mockAuth: false,
  mock2FA: false,
  autoLogin: false,
  mockInterceptor: false,
  mockPath: [],
  mockError: {},

  /* Authentication settings */
  oidcUserinfo: '/am/oauth2/userinfo',

  omitWidgets: [],
  omitWidgetsForOfficeUsers: ['epct.pending', 'epct.time_limits'],
  /* urls */
  /* GBD, GDD,  Madrid Monitor (all links to PROD) */
  globalDesignDbUrl: 'https://www.wipo.int/designdb/en/',
  globalDesignDbHelpUrl: 'https://www.wipo.int/designdb/en/designdb-help.jsp#saved_search',
  globalBrandDbHelpUrl: 'https://www.wipo.int/branddb/en/branddb-help.html#saved_search',
  madridMonitorUrl: 'http://www.wipo.int/madrid/monitor/',
  madridMonitorHelpUrl: 'https://www.wipo.int/madrid/en/how_to/monitor/eservices.html',
  madridMonitorWatchedIRNsUrl: 'https://www3.wipo.int/madrid/monitor/en/?mea=1',
  lisbonExpressUrl: 'https://www.wipo.int/ipdl/en/search/lisbon/',
  lisbonExpressAPIUrl: 'https://www.wipo.int/ipdl/lisbon/api/v1/query',
  wipoProofTokenUrl: 'secured/services/dashboard.xhtml?tgt=section_your_timestamps',
  wipoProofToProcessUrl: 'secured/services/dashboard.xhtml?tgt=section_timestamps_tobe_processed',
  wipoProofBundleUrl: 'secured/services/dashboard.xhtml?tgt=section_your_bundles',
  wipoProofPremiumUrl: 'secured/services/dashboard.xhtml?tgt=section_your_premium_certificates',
  wipoProofAPItokenURL: 'services/users/{{user}}/tokens',
  wipoProofAPIToProcessUrl: 'services/users/{{user}}/tokens/unprocessed',
  wipoProofAPIBundleUrl: 'services/users/{{user}}/bundles',
  wipoProofAPIPremiumUrl: 'services/users/{{user}}/premium-certs',
  aboutMetadataUrl: 'https://www.wipo.int/ipportal-support-api/ipportal/about/about.json',
  aboutDataUrl: 'https://www.wipo.int/ipportal-support-api/ipportal/about/{{lang}}/data.json',
  ocmsBasePath: 'https://wipo.int',

  //finance
  financeQuickStartGuide: 'https://www.wipo.int/finance/{{lang}}/docs/madrid-hague-fee-distribution-reports-quick-start-guide-{{lang}}.pdf',

  // other
  patentscopeQueriesAPIUrl: '-placeholder-',

  /* news temporary (or maybe permanently) point to PROD, TODO: remove if/once fixed! */
  urlNewsLetters: 'https://www3.wipo.int/resource-manager/api/v1/ipportal/',

  urlEPCTBase: '/ePCT-API-IPPORTAL-details/',
  get urlEPCTia() { return this.urlEPCTBase + 'ia'; },
  get urlEPCTWorkbenchAPI() { return this.urlEPCTBase + 'statistics/workbench'; },
  hostEPCT: '',
  hostEPCTUI: '',
  urlEPCTUI: '/ePCT/',
  urlEPCTWorbenchUI: '/ePCT/private/workbench.xhtml',
  urlPCTSearch: '/ePCT/private/iaSearch.xhtml',
  epct_tab: 'epct_workbench',
  urlIP: 'https://www.wipo.int/about-ip/',
  alertsPCT_API_basic: '/ePCT-API-IPPORTAL-basic/alerts?checkEmpty=false',
  alertsAPIUrl: '/alerts-manager/pa/api/v2/alerts',
  // TBD by environment
  homeVideo: 'https://www.wipo.int/portal/en/news/2019/article_0029.html',
  homeOnboarding: 'https://www.wipo.int/portal/en/news/2019/article_0029.html',
  languageCookieName: 'wipo_language',

  defaultTimezone: 'Switzerland',
  contextIsRoot: false,

  contactPage: 'https://www3.wipo.int/contact/',
  contactArea: '/area.jsp?area=ip_portal',

  wipoSiteTools: 'https://www.wipo.int/tools/',
  wipoSiteDisclaimPage: '/disclaim-ipportal.html',
  wipoSitePrivacyPage: '/privacy_policy-ipportal.html',

  wipoSitePortal: 'https://www.wipo.int/portal/',
  wipoSiteOnboarding: '/news/2019/article_0029.html',

  persistStore: true,
  debugStore: true,

  caching: {
    default: 3600000,
    newsSources: 3600000,
    newsArticles: 3600000,
    epctPending: 900000,
    epctTimeLimits: 900000,
    globaldbSearch: 0,
    globaldbSaveQueries: 0,
    madridMonitorSearch: 0,
    madridMonitorIrn: 0,
    msmp: 0,
    finance: 3600000
  },
  feedbackGizmo: 'https://surveys.wipo.int/s3/Survey-on-WIPO-IP-Portal',
  surveyId: '90389219', // '5593898',
  surveyConditions: [
    {
      id: '1-3',
      moreId: '1-5',
      moreAreaId: 'sgE-90389219-1-5-element',
      askMoreIfSelected: ['10029', '10030']
    },
    {
      id: '1-4',
      moreId: '1-6',
      moreAreaId: 'sgE-90389219-1-6-element',
      askMoreIfSelected: ['10034', '10035']
    }
  ],
  backend: {
    base: undefined,
    page_info: undefined
  },
  wipo_environments: {
    live: ['www', 'www3', 'patentscope', 'wipopearl', 'ipportal'],
    test: ['acc', 'www5', 'www3-test', 'pcttest', 'www-test', 'wipolex.acc'],
    demo: ['webaccess', 'pctdemo'],
    dev: ['dev', 'local', 'www3dev', 'pctdev', 'wwwdev']
  },
  landing: {
    pct: 'https://pct.wipo.int/ePCT/',
    madrid: 'https://madrid.wipo.int/',
    hague: 'https://hague.wipo.int/#/landing/home',
    lisbon: 'https://www.wipo.int/lisbon/en/'
  },
  stats: {
    patents: 'https://patentscope.wipo.int/search/psNbRecs.jsf',
    dbinfo: 'https://www3.wipo.int/branddb/dbInfo.js'
  },
  store: {
    // do not cache in session storage
    doNotPersistKeys: [
      ['global', 'data', 'menu'],
      ['global', 'routingInfo'], // do not persist the routing info
      ['widgets', 'data', 'alerts'] // nuke alerts
    ]
  }, enableSmartlingCapture: false, acceptance: false,
};

